import ProfileCompleteNotice from "../Dashboard/ProfileCompleteNotice";
import BannerCarasoul from "./Carasoul/BannerCarasoul";
import MainCarasoul from "./Carasoul/MainCarasoul";
import classes from "./LandingPageCmpUser.module.css";

import { Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBannerByLocation } from "../../services/LandingPageService";
import { getAllProducts } from "../../store/slice/generalData_slice";
import BlogCarasoul from "./Carasoul/BlogCarasoul";

const { Title } = Typography;

const LandingPageCmpUser = () => {
  //API Integration start
  const [eventData, setEventData] = useState([]);
  const dispatch = useDispatch();
  const percentage = useSelector((state) => state.percentage.value);
  const product = useSelector((state) => state.generalData.products);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const showbadge = percentage != 100;
  const userObj = useSelector((state) => state.user.data);

  const getData = async (userId) => {
    try {
      let bannerDataObj = { location: "LOGGED_IN_LANDING_IMAGE" };
      const res = await getBannerByLocation(bannerDataObj);
      dispatch(
        getAllProducts({
          offset: 0,
          pageNumber: 1,
          pageSize: 30,
          userId,
        }),
      );

      if (res && res.data.content) {
        setEventData(res.data.content);
      } else {
        setEventData([]);
      }
    } catch (error) {
      setEventData({});
    }
  };
  useEffect(() => {
    if (userObj.id) {
      getData(userObj.id);
    }
  }, [userObj]);
  useEffect(() => {
    if (typeof product !== "string") {
      if (product == 0) {
        const timer = setTimeout(() => {
          setIsDialogVisible(true);
        }, 2000);

        return () => clearTimeout(timer);
      } else {
        setIsDialogVisible(false);
      }
    }
  }, [product]);

  //API Integration end

  return (
    <div className={classes.container}>
      <MainCarasoul />
      {showbadge && <ProfileCompleteNotice />}

      <div className={classes.eventImgContainer}>
        {eventData?.map((banner) => (
          <img
            key={banner.id}
            src={banner.bannerPath}
            className={classes.eventImgSrc}
            alt={banner.title}
            onClick={() => window.open(banner?.redirectUrl, "_blank")}
          />
        ))}
      </div>

      {/* <div className={classes.eventImgTxt}>
          <Title level={2}>{eventData.title}</Title>
          <p>{eventData.description}</p>
        </div> 
       </div> */}
      <section className={classes.blogsections}>
        <Title level={3}>PriBlogs</Title>
        <BlogCarasoul />
      </section>

      <div className={classes.bottomPadding}>
        <BannerCarasoul />
      </div>
      <Modal
        title="Complete Your Profile"
        visible={isDialogVisible}
        onOk={() => setIsDialogVisible(false)}
        onCancel={() => setIsDialogVisible(false)}
        okText="Okay"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>
          Your profile is not visible in the Departments or Categories. To make
          it visible, please add your Department and Category by selecting{" "}
          <strong>+Add Product </strong>
          in the “My Products” section.
        </p>
      </Modal>
    </div>
  );
};

export default LandingPageCmpUser;
