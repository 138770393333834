import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import VerificationStrip from "../General/VerificationStrip";
import Header from "../Header/Header";
import classes from "./Layout.module.css";

import { useSelector } from "react-redux";
const { Header: AntDHeader, Footer: AntDFooter, Content } = Layout;

const MainLayout = () => {
  const userObj = useSelector((state) => state.user.data);
  const location = useLocation();
  const isVerifyEmailRoute = location.pathname.includes("/public/verify-email");

  console.log("!userObj.email:", !userObj.email);
  const show =
    localStorage.getItem("token") &&
    !isVerifyEmailRoute &&
    userObj?.isVerified !== true;

  const [scrolltopdata, setscrolltopdata] = useState("");

  //window.scrollY < window.innerHeight
  useEffect(() => {
    if (userObj.id === undefined || userObj.id === null)
      window.addEventListener("scroll", () => {
        if (window.scrollY < window.innerHeight) {
          setscrolltopdata(classes.headerTop);
        } else {
          setscrolltopdata(classes.scrolled);
        }
      });
  }, []);

  return (
    <Layout className={classes.mainLayout}>
      <AntDHeader className={`${scrolltopdata}`}>
        <Header />
      </AntDHeader>

      <Content className={classes.content}>
        {show && <VerificationStrip />}
        <Outlet />
      </Content>
      <AntDFooter className={classes.footer}>
        <Footer />
      </AntDFooter>
    </Layout>
  );
};

export default MainLayout;
